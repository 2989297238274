<template>
  <div class="career-container">
    <vue-element-loading :active="loading" is-full-screen>
      <img src="@/assets/gif/Motion-Logo-.gif" width="50%" style="max-width: 250px;">
    </vue-element-loading>
    <Navbar @lang="getLang" color="white" />

    <div id="career-form-wrapper" :style="{'min-height': windowHeight + 'px'}">
      <div id="content-wrapper">
        <div class="form-title">
          <p>{{lang === 'id' ? 'Kamu melamar sebagai' : 'You\'re applying for'}}</p>
          <p style="color: #0054B3; font-size: 30px; font-weight: 1000">{{ formTitle }}</p>
        </div>
        <div id="form-career">
          <div id="form">
            <div class="form-col">
              <div class="label">
                <p>{{lang === 'id' ? 'Nama Lengkap' : 'Full Name'}}</p>
                <p class="err-text"  v-if="errName">{{errName}}</p>
              </div>
              <div class="input-container">
                <input type="text" v-model="name">
              </div>
            </div>
            <div class="form-col">
              <div class="label">
                <p>{{lang ==='id' ? 'Jenis Kelamin' : 'Gender'}}</p>
                <p class="err-text" v-if="errGender">{{errGender}}</p>
              </div>
              <div class="input-container">
                <!-- <input type="text" v-model="name"> -->
                <div id="select-gender">
                  <div id="male">
                    <div class="select-target" :class="[gender === 'male' ? 'selected' : null]" @click.prevent="gender = 'male'">
                    </div>
                    <p>{{lang === 'id' ? 'Laki-laki': 'Male'}}</p>
                  </div>
                  <div id="female">
                    <div class="select-target" :class="[gender === 'female' ? 'selected' : null]" @click.prevent="gender = 'female'"></div>
                    <p>{{lang === 'id' ? 'Perempuan' : 'Female'}}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-col">
              <div class="label">
                <p>{{lang === 'id' ? 'Alamat' : 'Address'}}</p>
                <p class="err-text" v-if="errAddr">{{errAddr}}</p>
              </div>
              <div class="input-container">
                <input type="text" v-model="address">
              </div>
            </div>
            <div class="form-col">
              <div class="label">
                <p>Email</p>
                <p class="err-text" v-if="errMail">{{errMail}}</p>
              </div>
              <div class="input-container">
                <input type="email" v-model="email">
              </div>
            </div>
            <div class="form-col">
              <div class="label">
                <p>{{lang === 'id' ? 'Nomor Telepon' : 'Phone Number'}}</p>
                <p class="err-text" v-if="errTlp">{{errTlp}}</p>
              </div>
              <div class="input-container">
                <input type="tel" v-model="telp">
              </div>
            </div>
            <div class="form-col">
              <div class="label">
                <p>{{lang === 'id' ? 'CV / Portofolio' : 'CV / Portfolio'}}</p>
                <p class="err-text" v-if="errCv">{{errCv}}</p>
              </div>
              <div class="input-file">
                <input v-if="uploadReady" class="custom-file-input" type="file" accept=".pdf" @change="getFile" ref="file">
              </div>
            </div>
            <div class="form-col">
              <div class="label">
                <!-- <p>Nama Lengkap</p> -->
              </div>
              <div class="input-container">
                <div v-if="!loadingBtn" id="btn-save" @click.prevent="saveCareer"><h4>{{lang === 'id' ? 'Kirim' : 'Send'}}</h4></div>
                <div id="btn-save" v-else>
                 <vue-element-loading :active="loadingBtn" spinner="spinner" color="#FFF"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Footer -->
    <Footer />
  </div>
</template>

<script>
import Navbar from '@/components/Navbar'
import Footer from '@/components/Footer'

import axios from 'axios'
const baseUrl = 'https://apiw.higo.id/web-savecareer'
export default {
  components: {
    Navbar,
    Footer
  },
  data () {
    return {
      options: {
        url: 'upload',
        maxFilesize: 1,
        maxFiles: 1,
        acceptedFiles: ['application/pdf']
      },
      lang: '',
      windowHeight: 0,
      formTitle: '',
      name: '',
      errName: false,
      gender: 'male',
      errGender: false,
      address: '',
      errAddr: false,
      email: '',
      errMail: false,
      telp: '',
      errTlp: false,
      file: '',
      errCv: false,
      loadingBtn: false,
      uploadReady: true,
      loading: false
    }
  },
  metaInfo: {
    title: 'Career | HIGO'
  },
  methods: {
    getLang (value) {
      this.lang = value
      this.loading = true
      setTimeout(() => {
        this.loading = false
      }, 550)
    },
    getFile () {
      this.file = this.$refs.file.files[0]
    },
    checkEmail (email) {
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(email)
    },
    checkPhone (str) {
      const re = /^(^\+62\s?|^0)(\d{3,4}-?){2}\d{3,4}$/g
      return re.test(str)
    },
    saveCareer () {
      this.loadingBtn = true
      let err = false
      if (!this.name) {
        this.errName = '*Nama harus diisi'
        err = true
      }

      if (!this.gender) {
        this.errGender = '*Pilih jenis kelamin'
        err = true
      }

      if (!this.address) {
        this.errAddr = '*Alamat harus diisi'
        err = true
      }

      if (!this.email) {
        this.errMail = '*Alamat email harus diisi'
        err = true
      } else {
        const valid = this.checkEmail(this.email)
        if (!valid) {
          err = true
          this.errMail = '*Format email tidak valid'
        }
      }

      if (!this.telp) {
        this.errTlp = '*Nomor Telepon harus diisi'
        err = true
      } else {
        const valid = this.checkPhone(this.telp)

        if (!valid) {
          err = true
          this.errTlp = '*Format nomor telp tidak valid'
        }
      }

      if (!this.file) {
        this.errCv = '*Upload cv/portofolio'
        err = true
      }

      if (err) this.loadingBtn = false
      else this.sendCareer()
    },
    sendCareer () {
      const formData = new FormData()

      formData.append('name', this.name)
      formData.append('position', this.$route.params.job.split('-').join(' '))
      formData.append('gender', this.gender)
      formData.append('address', this.address)
      formData.append('email', this.email)
      formData.append('phone', this.telp)
      formData.append('pdffile', this.file)
      formData.append('form_url', sessionStorage.getItem('from-url-career') ? sessionStorage.getItem('from-url-career') : 'https://higo.id/careers')

      axios({
        method: 'POST',
        data: formData,
        url: baseUrl
      })
        .then(({ data }) => {
          this.loadingBtn = false
          this.resetForm()
          if (data.success) {
            this.$toast.open({
              message: `Success\nThanks for Submitting\n`,
              type: 'success',
              position: 'bottom-right',
              duration: 5000,
              dismissible: true
            })
          } else {
            this.$toast.open({
              message: data.message,
              type: 'warning',
              position: 'bottom-right',
              duration: 5000,
              dismissible: true
            })
          }
        })
        .catch(err => {
          console.log(err, 'went wrong')
        })
    },
    resetForm () {
      this.name = ''
      this.type = ''
      this.gender = 'male'
      this.address = ''
      this.email = ''
      this.telp = ''
      this.file = ''
      this.errName = ''
      this.errGender = ''
      this.errAddr = ''
      this.errMail = ''
      this.errTlp = ''
      this.errCv = ''
      this.uploadReady = false
      this.$nextTick(() => {
        this.uploadReady = true
      })
    }
  },
  created () {
    // Available position
    const availablePosition = [
      {
        'name': 'ui-ux-designer',
        'position': 'UI UX Designer'
      },
      {
        'name': 'legal',
        'position': 'Legal'
      },
      {
        'name': 'graphics-designer',
        'position': 'Graphic Designer'
      },
      {
        'name': 'industrial-engineer',
        'position': 'Industrial Engineer'
      },
      {
        'name': 'merchant-marketing-specialist',
        'position': 'Merchant Marketing Specialist'
      },
      {
        'name': 'junior-strategist',
        'position': 'Junior Strategist'
      },
      {
        'name': 'business-development-isp-partnership',
        'position': 'Business Development - ISP Partnership'
      },
      {
        'name': 'business-development-sme',
        'position': 'Business Development - SME'
      },
      {
        'name': 'product-manager',
        'position': 'Product Manager'
      },
      {
        'name': 'software-developer',
        'position': 'Software Developer'
      },
      {
        'name': 'quality-assurance',
        'position': 'Quality Assurance'
      },
      {
        'name': 'data-scientist',
        'position': 'Data Scientist'
      }
    ]

    const getPosition = (arr, name) => {
        const result = arr.filter(el => el.name == name);
        if (result.length) {
          return result[0].position
        }
        return null
    }

    const position = getPosition(availablePosition, this.$route.params.job)
    if (!position) {
      this.$router.push('/careers/')
    }
    this.formTitle = position

    window.scrollTo(0, 0)
    this.windowHeight = window.innerHeight
  }
}
</script>

<style>
.career-container {
  width: 100%;
  min-height: 100vh;
}

#career-form-wrapper {
  width: 100%;
  height: 100vh;
  padding: 3rem;
  margin-bottom: 3rem;
}

#content-wrapper {
  width: 90%;
  height: 100%;
  padding: 1.5rem;
  margin: 0 auto;
}

#content-wrapper h1 {
  font-size: 1.8rem
}

#form-career {
  width: 100%;
  height: 95%;
  margin-top: 2%;
}

#form {
  width: 100%;
  /* height: 85%; */
  /* Desktop */
  height: 30%;
  min-height: 400px;
  display: grid;
  grid-template-rows: repeat(7, 1fr);
  grid-row-gap: 10px;
  margin-top: 2rem;
}

.form-col {
  display: grid;
  grid-template-columns: .5fr 2fr;
  max-height: 3.2rem;
}

.form-col .label {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.input-container, .input-file {
  /* background: red; */
  padding: 1% 2%;
}

.input-container input {
  width: 100%;
  height: 100%;
  border: 1px solid silver;
  border-radius: 20px;
  padding: 0 3%;
}

.err-text {
  color: red;
  font-size: .7rem;
}

#btn-save {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  background-color: #0245ab;
  display: grid;
  place-items: center;
  transition: .3s;
  cursor: pointer;
}

#btn-save:hover {
  background-color: rgb(0,219,189);
}

#btn-save {
  margin: auto;
  color: #fff;
}

#select-gender {
  height: 100%;
  display: flex;
  align-items: center;
}

#male, #female {
  display: flex;
  height: 100%;
  align-items: center;
}

#male {
  margin-right: 3rem;
}

#male p, #female p {
  font-size: .85rem;
}

.select-target {
  width: 2rem;
  height: 2rem;
  border-radius: 10px;
  border: 1px solid silver;
  background-color: white;
  transition: .3s ease-out;
  margin-right: .8rem;
}

.selected, .select-target:hover {
  background-color: rgb(0,219,189);
}

/* .custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}
.custom-file-input::before {
  content: 'Select some files';
  display: inline-block;
  background: linear-gradient(top, #f9f9f9, #e3e3e3);
  border: 1px solid #999;
  border-radius: 3px;
  opacity: 1;
  padding: 5px 8px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  text-shadow: 1px 1px #fff;
  font-weight: 700;
  font-size: 10pt;
}
.custom-file-input:hover::before {
  border-color: black;
}
.custom-file-input:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
} */

@media (max-width: 767.98px) {
  .form-col {
    display: grid;
    grid-template-columns: unset;
    grid-template-rows: .5fr 2fr;
    grid-row-gap: .5rem;
  }

  .form-col .label {
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  @media (max-width:570px) {
    .form-title {
      font-family:'Calibri', sans-serif;
      padding: 1rem 1rem;
    }
  }

  @media (min-width:570px) and (max-width:1200px) {
    .form-title {
      font-family:'Calibri', sans-serif;
      padding: 1rem 1rem;
    }
  }

  @media (min-width: 1200px) {
    .form-title {
      font-family:'Calibri', sans-serif;
      padding: 0px 3rem;
    }
  }


  .form-col .label p  {
    margin-right: 1rem;
    /* Mobile version */
    margin-left: 1rem;
  }

  #career-form-wrapper {
    height: auto;
    padding: 3rem 5%;
  }

  #content-wrapper {
    width: 90%;
    height: 100%;
    padding: 2rem 0 0 0;
    margin: 0 auto;
  }

  #form {
    grid-row-gap: 1.5rem;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  #content-wrapper {
    padding: 2rem 0 0 0;
    width: 100%;
  }

  #career-form-wrapper {
    padding: 3.5rem 5%;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  #content-wrapper {
    width: 95%;
  }
}

@media (min-width: 1200px) {
  #content-wrapper {
    max-width: 1140px;
    padding: 4% 0 0 0;
  }

  #content-wrapper h1 {
    font-size: min(2vw, 28px);
  }

  #form {
    padding: 0 5%;
  }

  .input-container input {
    width: 100%;
  }
}

@media (max-width: 575.98px) {
  #male {
    margin-right: 2rem;
  }
}

@media (max-width: 375px) {
  #male {
    margin-right: 1rem;
  }

  #content-wrapper {
    margin: 0;
  }
}
</style>
